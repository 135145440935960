@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/**
* controlles the toast container and toast
* seperate width for mobile and desktop
*/
.toast-container {
  @apply px-4 mt-10 md:mt-0 w-full md:max-w-lg lg:max-w-xl;
}

.toast {
  @apply rounded-lg shadow-lg; /* Add border radius, shadow, and responsive width */
}

/**
* this is for qr scanner video element
* src/views/admin/viewStores/AttachQr.js
*/
#qr-reader video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

/* Add this to your CSS file */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
