#pageLoader {
  display: none; /* Initially hidden */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}

#pageLoader.block {
  display: flex;
}

.MuiOutlinedInput-input {
  height: 5px !important;
  font-size: 12px !important;
  border-radius: 10px !important;
}
.MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.MuiInputBase-input {
  font-size: 13px !important;

  height: 13px !important;
  border-radius: 10px !important;

  outline-style: none !important;
}
.cursor-pointer {
  cursor: pointer;
}

@media print {
  /* @page {
    size: A4 landscape;
  } */
  .print\:page-break-before-always {
    page-break-before: always;
  }
  .print\:h-auto {
    height: auto;
  }
  .print\:w-full {
    width: 100%;
  }
  .print\:page-break-inside-avoid {
    page-break-inside: avoid;
  }
  .print\:grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .print\:gap-4 {
    gap: 1rem;
  }
}
